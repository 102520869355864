@use 'theme';
@use 'breakpoints';

.wrapper {
  margin: theme.spacing(4, 'auto', 0);
  max-width: 1259px;

  & .imageWrapper {
    justify-content: center;
    gap: theme.spacing(0);
  }
}

.image {
  & img {
    width: 121px;
    height: 24px;

    @include breakpoints.up('small') {
      width: 182px;
      height: 36px;
    }
  }
}

.header {
  color: theme.palette('darkGold');
  flex-direction: column;

  @include breakpoints.up('small') {
    flex-direction: row;
  }
}

.headerText {
  display: block;

  @include breakpoints.up('small') {
    display: flex;
  }
}

.partnerStar {
  margin-right: theme.spacing(1);
  color: #E9CD6C;
}
